<template>
<div class="nosotros">
  <Nosotros :lang="lang" :seccion="seccion" />
</div>
</template>

<script>
import Nosotros from './Nosotros'
import Api from '@/components/Api'
export default {
  name: 'NosotrosHandler',
  components: {
    Nosotros
  },
  props: {
    seccion: {
      type: String,
      default: 'es'
    }
  },
  data() {
    return {
      lang: Api.obtenerLocal('lang') || 'es'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.area {
  background-color: white;
  min-height: 100vh;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
